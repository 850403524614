import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Container, MenuItem, Select } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { isValid } from 'date-fns';

import StyledSearchMenu from './styles';
import ControlledInput from '../../../../../components/ControlledInput';
import CustomButton from '../../../../../components/CustomButton';
import { IGenericEntry } from '../../../../../interfaces/IGenericSection';
import ControlledSelect from '../../../../../components/ControlledSelect';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import Label from '../../../../../components/Label';
import StyledFormControl from '../../../../../components/ControlledSelect/styles';
import SearchQueryModal from '../SearchQueryModal';
import environment from '../../../../../environment';
import ProductCodeSearch from '../../../../../components/ProductCodeSearch';
import SkuGroupSearch from '../../../../../components/SkuGroupSearch';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  productDescription: string;
  setProductDescription: CallableFunction;
  stockPot: string;
  setStockPot: CallableFunction;
  subinventories: string[];
  statusOperator: IGenericEntry;
  setStatusOperator: CallableFunction;
  status: string;
  setStatus: CallableFunction;
  statuses: string[];
  createDateOption: IGenericEntry;
  setCreateDateOption: CallableFunction;
  createDateOptions: IGenericEntry[];
  startDate: Date | null;
  setStartDate: CallableFunction;
  endDate: Date | null;
  setEndDate: CallableFunction;
  setDateRange: CallableFunction;
  statusOperators: IGenericEntry[];
  handleSubmit: (event: SyntheticEvent) => void;
}

const SearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  productDescription,
  setProductDescription,
  stockPot,
  setStockPot,
  subinventories,
  statusOperator,
  setStatusOperator,
  statusOperators,
  status,
  setStatus,
  statuses,
  createDateOption,
  setCreateDateOption,
  createDateOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setDateRange,
  handleSubmit
}) => {
  const [selectedQuery, setSelectedQuery] = useState('');
  const [reportQueries, setReportQueries] = useState([]);
  const [skuGroupNames, setSkuGroupNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [invalidDate, setInvalidDate] = useState<boolean>(false);

  const handleQuerySelection = (event: any) => {
    const searchOptions = event.target.value.queryData;
    const operator = statusOperators.find(
      (op: IGenericEntry) => op.code === searchOptions.statusOperator.code
    );
    const dateRange = createDateOptions.find(
      (op: IGenericEntry) =>
        op.code ===
        (searchOptions.dateRange ? searchOptions.dateRange : 'dateRange')
    );
    const createDateStart = searchOptions.createDateStart
      ? new Date(searchOptions.createDateStart)
      : null;
    const createDateEnd = searchOptions.createDateEnd
      ? new Date(searchOptions.createDateEnd)
      : null;

    setSelectedQuery(event.target.value);
    setProductCodes(searchOptions.productCodes);
    setProductDescription(searchOptions.descriptions[0]);
    setStockPot(searchOptions.stockPot);
    setStatusOperator(operator === undefined ? statusOperators[0] : operator);
    setStatus(searchOptions.status);
    setStartDate(createDateStart);
    setEndDate(createDateEnd);
    setCreateDateOption(dateRange);
    setInvalidDate(false);

    if (!isValid(createDateStart)) {
      setStartDate(null);
    }

    if (!isValid(createDateEnd)) {
      setEndDate(null);
    }
  };

  const clearSearchOptions = () => {
    setSelectedQuery('');
    setProductCodes([]);
    setSkuGroupNames([]);
    setProductDescription('');
    setStockPot('');
    setStatusOperator(statusOperators[0]);
    setStatus('');
    setStartDate(null);
    setEndDate(null);
    setCreateDateOption(createDateOptions[0]);
    setErrorMessage('');
    setInvalidDate(false);
  };

  useEffect(() => {
    const fetchReportQueries = async () => {
      try {
        const response = await axios.get(`${environment.apiPath}reportQuery`, {
          ...environment.params
        });
        setReportQueries(response.data);
      } catch (err: AxiosError | any) {
        toast.error(err.message);
      }
    };

    fetchReportQueries();
  }, []);

  return (
    <StyledSearchMenu>
      <Container fixed>
        <div className='search-menu-row row-header'>
          <h2>Search Orders</h2>
          <Label
            id='search-query'
            control={
              <StyledFormControl variant={'outlined' as any}>
                <Select
                  data-testid='select-query-testid'
                  displayEmpty
                  value={selectedQuery}
                  onChange={handleQuerySelection}
                >
                  <MenuItem key='placeholder' value='' disabled>
                    Select saved search query
                  </MenuItem>
                  {reportQueries.map((query: any) => (
                    <MenuItem key={query.queryId} value={query}>
                      {query.queryName}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            }
            label='Saved search query'
            classes='label--w-45'
          />
        </div>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <SkuGroupSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              skuGroupNames={skuGroupNames}
              setSkuGroupNames={setSkuGroupNames}
              style={{ width: '100%' }}
            />
          </div>
          <br />
          <div className='search-menu-row'>
            <ProductCodeSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              style={{ width: '50%' }}
            />
            <ControlledInput
              id='product-description'
              placeholder='Type item description here'
              value={productDescription}
              handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                setProductDescription(event.target.value)
              }
              label='Item description'
              type='text'
              classes='label--w-45 label--h-77'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <Label
              id='sub-inventory'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={stockPot}
                    onChange={(event: any) => setStockPot(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select sub-inventory
                    </MenuItem>
                    {subinventories.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Sub-inventory'
              classes='label--w-30'
            />
            <ControlledSelect
              id='status-operator'
              value={statusOperator}
              handleChange={(event: any) =>
                setStatusOperator(event.target.value)
              }
              label='Status'
              options={statusOperators}
              classes='label--w-30'
            />
            <Label
              id='status'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={status}
                    onChange={(event: any) => setStatus(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select status
                    </MenuItem>
                    {statuses.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label=''
              classes='label--w-30 mt-22'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledSelect
              id='create-date-option'
              value={createDateOption}
              handleChange={(event: any) => {
                setCreateDateOption(event.target.value);
                setInvalidDate(false);
              }}
              label='Create date'
              options={createDateOptions}
              classes='label--w-30'
            />
            <CustomDatePicker
              id='start-date'
              value={startDate}
              handleChange={(event: any) => {
                setInvalidDate(!isValid(new Date(event)));
                setDateRange(true);
                setStartDate(event);
              }}
              label='Start date'
              classes='label--w-30'
            />
            <CustomDatePicker
              id='end-date'
              value={endDate}
              handleChange={(event: any) => {
                setInvalidDate(!isValid(new Date(event)));
                setDateRange(true);
                setEndDate(event);
              }}
              label='End date'
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <div className='search-btn-wrapper'>
              <CustomButton
                type='button'
                classes='btn--w-200-px btn--light-grey clear-btn'
                title='Clear'
                handleClick={() => clearSearchOptions()}
              />
              <SearchQueryModal
                productCodes={productCodes}
                productDescription={productDescription}
                stockPot={stockPot}
                statusOperator={statusOperator}
                createDateOption={createDateOption.code}
                status={status}
                startDate={startDate}
                endDate={endDate}
                reportQueries={reportQueries}
                setReportQueries={setReportQueries}
                invalidDate={invalidDate}
              />
            </div>
            <CustomButton
              type='submit'
              disabled={invalidDate}
              classes='btn--w-200-px'
              title='Search'
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default SearchMenu;
