import React from 'react';
import { Tooltip } from '@mui/material';

interface ICustomTooltipProps {
  title: string;
  iconlabel?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  arrow?: boolean;
  followCursor?: boolean;
  enterDelay?: number;
  leaveDelay?: number;
  disableInteractive?: boolean;
  children?: React.ReactNode;
}

const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  title,
  iconlabel,
  placement,
  arrow,
  followCursor,
  enterDelay,
  leaveDelay,
  disableInteractive,
  children
}) => {
  const renderTooltip = () => {
    if (iconlabel) {
      return <img src={title} alt={iconlabel} />;
    }

    return <span>{title}</span>;
  };

  return (
    <Tooltip
      title={renderTooltip()}
      arrow={arrow}
      placement={placement}
      followCursor={followCursor}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      disableInteractive={disableInteractive}
    >
      <span style={{ display: 'inline-block' }}>{children}</span>
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  iconlabel: '',
  placement: 'bottom-start',
  arrow: true,
  followCursor: false,
  enterDelay: 50,
  leaveDelay: 0,
  disableInteractive: true
};

export default CustomTooltip;
