import React, { SyntheticEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import environment from '../../../../../environment';
import NewProductIcon from '../../../../../assets/icons/new-product.png';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';
import GenericModal from '../../../../../components/GenericModal';

const BulkPreorderReleaseModal: React.FC<{
  id: string;

  rowData: ISkuGroup[];
}> = ({ id, rowData }) => {
  const group = rowData.filter((item: ISkuGroup) => item.id === id);

  if (!group || !group[0].canReleasePreordersList) {
    return null;
  }

  const isPreorderInGroup = group[0].canReleasePreordersList.some(
    (item) => item.canReleasePreorders === true
  );

  if (!isPreorderInGroup) {
    return null;
  }

  const countPreorders = group[0].canReleasePreordersList.filter(
    (item) => item.canReleasePreorders === true
  ).length;

  const countAllProducts = group[0].products.length;

  const preordersProductCodes = group[0].canReleasePreordersList
    .filter((item) => item.canReleasePreorders === true)
    .map((item) => item.productCode);

  const warnings: string[] = [
    'Pre-orders will be released based on the stock availability of each SKU'
  ];

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      try {
        const res = await axios.post(
          `${environment.apiPath}releasePreorders`,
          {
            productCodes: preordersProductCodes,
            subinventory: 'MULTICHANL'
          },
          { ...environment.params }
        );
      } catch (err: AxiosError | any) {
        toast.error(err.message);
      } finally {
        setOpen(false);
        setLoading(false);
      }
    };

  return (
    <GenericModal
      iconBtn={NewProductIcon}
      confirmationMessage={`Please confirm you want to release the pre-orders for SKUs:
         (${countPreorders} out of ${countAllProducts}) ${preordersProductCodes}`}
      warnings={warnings}
      handleSubmit={handleSubmit}
    />
  );
};

export default BulkPreorderReleaseModal;
