import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const AuthVerify: React.FC<any> = ({
  userState,
  setUserState,
  setIsToastVisible
}) => {
  let decodedJwt: any;
  const location = useLocation();

  const handleSignOut = async () => {
    await Auth.signOut();
    setUserState({ authState: 'signedOut', user: undefined });
  };

  useEffect(() => {
    sessionStorage.setItem('reloaded', 'yes');
  }, []);

  if (sessionStorage.getItem('reloaded') === null) {
    localStorage.clear();
  }

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  if (userState.user) {
    const accessToken =
      userState.user?.userData?.signInUserSession?.accessToken?.jwtToken;
    decodedJwt = parseJwt(accessToken);
  }

  // log out user on any api request, when access token expires
  axios.interceptors.request.use((request) => {
    if (decodedJwt && decodedJwt.exp * 1000 < moment().valueOf()) {
      setIsToastVisible(false);
      handleSignOut();
    } else if (userState.user) {
      // Add idToken on every req
      if (
        !request.url?.match(
          '^https://cavendishengine-[a-z0-9]+.s3.amazonaws.com/.*'
        )
      ) {
        request.headers.Authorization =
          userState.user?.userData?.signInUserSession?.idToken?.jwtToken;
      }
    }

    return request;
  });

  // log out user on tab change or refresh, when access token expires
  useEffect(() => {
    if (
      userState.user &&
      decodedJwt &&
      decodedJwt.exp * 1000 < moment().valueOf()
    ) {
      handleSignOut();
    }
  }, [location.pathname, userState]);

  return <></>;
};

export default AuthVerify;
